import { v4 as uuidv4 } from 'uuid';
import { apiBaseUrl } from '../config';
import { getUserBearer, getUserId } from './authActions';
import { storage } from '../services/firebase';

export default {
  async saveCanvas(frameType, dataBlob, thumbnail_blob, product_sku) {
    let token = null;
    try {
      token = await getUserBearer();
    } catch (e) {
      console.warn('User is not logged in, proceeding without token');
    }

    try {
      const newUploadFileUrl = await this.uploadBitmapDesign(dataBlob, frameType);
      const thumbnails = await this.createThumbnails(thumbnail_blob);
      const thumbnailUrls = {
        1280: await this.uploadBitmapDesign(thumbnails[1280], `${frameType}_thumb_1280`),
        640: await this.uploadBitmapDesign(thumbnails[640], `${frameType}_thumb_640`)
      };

      const requestBody = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          frameType: frameType,
          image_path: newUploadFileUrl,
          thumbnail_path_640: thumbnailUrls[1280],
          thumbnail_path_100: thumbnailUrls[640],
          product_sku
        }),
      };

      if (token) {
        requestBody.headers.Authorization = `Bearer ${token}`;
      }

      const response = await fetch(`${apiBaseUrl}/photoEditor`, requestBody);
      if (!response.ok) {
        throw new Error('Error uploading file');
      }
      
      const responseJson = await response.json();
      // supporting api v4 and v5 when v5 is live just remove the || responseJson
      return responseJson?.data || responseJson;

    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  },
  async uploadBitmapDesign(fileBlob, frameType, prefix = 'bitmap-designs') {
    try {
      const ext = frameType.includes('thumb') ? 'jpg' : (frameType === 'postcard' ? 'pdf' : 'jpg');
      const storageRef = storage.ref();
      const filename = `photo-editor/${prefix}-${uuidv4()}-${Date.now()}.${ext}`;
      const fileRef = storageRef.child(filename);
      const metadata = {
        contentType: ext === 'pdf' ? 'application/pdf' : 'image/jpeg',
      };
      await fileRef.put(fileBlob, metadata);
      return filename;
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  },
  async createThumbnails(dataBlob) {
    const img = await createImageBitmap(dataBlob);
    const thumbnailSizes = [1280, 640];
    const thumbnails = {};

    for (const size of thumbnailSizes) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      
      const isWidthLarger = img.width > img.height;
      const aspectRatio = img.width / img.height;

      if (isWidthLarger) {
        canvas.width = size;
        canvas.height = size / aspectRatio;
      } else {
        canvas.height = size;
        canvas.width = size * aspectRatio;
      }

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      thumbnails[size] = await new Promise((resolve) => {
        canvas.toBlob(resolve, 'image/jpeg', 0.7);
      });
    }

    return thumbnails;
  },
};
